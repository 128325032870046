import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import themes from 'src/style/themes';
import colors from 'src/style/colors';
import fonts from 'src/style/fonts';
import sizes from 'src/style/sizes';

/**
 * @type React.FunctionComponent<{ theme?: string }>
 */
const Theme = ({ children, theme = 'primary' }) => {
  if (!themes[theme]) {
    console.warn(`Invalid theme name "${theme}"`);
  }

  /**
   * @type AppTheme
   */
  const themeData = React.useMemo(() => {
    return {
      themeName: theme,
      ...themes[theme],
      colors,
      fonts,
      sizes,
    };
  }, [theme]);
  return <ThemeProvider theme={themeData}>{children}</ThemeProvider>;
};

export default Theme;
