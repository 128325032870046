import React from 'react';
import { mediaQueries } from 'src/style/breakpoints';

const RightContent = ({ children, sizeRight, reverse }) => {
  return (
    <div
      css={{
        height: '100%',
        marginBottom: reverse ? 31 : 0,
        flex: sizeRight,
        [mediaQueries.phoneOnly]: {
          flex: 1,
          width: '100%',
        },
        [mediaQueries.tabletLandscapeUp]: {
          marginBottom: 0,
        },
      }}
    >
      {children}
    </div>
  );
};

export default RightContent;
