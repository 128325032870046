import React from 'react';

const PlayButton = ({ size }) => {
  return (
    <div
      css={{
        height: size === 'small' ? 50 : 78,
        width: size === 'small' ? 50 : 78,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
      }}
    >
      <svg
        width="25px"
        height="25px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform={size === 'small' ? 'scale(0.65)' : null}
      >
        <path
          d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z"
          fill="#5C48D3"
        />
      </svg>
    </div>
  );
};
export default PlayButton;
