import React, { useEffect, useState } from 'react';
import Logo from '../Logo';
import { animated } from 'react-spring';
import colors from 'src/style/colors';
import sizes from 'src/style/sizes';
import throttle from 'lodash.throttle';
import { AnchorButton } from '../Button';
import Theme from '../Theme';
import { MenuItem, MenuItemNotLink } from './elements';
import { useTrackedSpring } from '../../utils/useTrackedSpring';
import DropdownMenuItem from './DropdownMenuItem';
import { useMediaQueries } from '../../utils/MediaQueries';
import MenuClosed from '../Icons/MenuClosed';
import MenuOpened from '../Icons/MenuOpened';
import HamburgerMenu from './HamburgerMenu';
import { Manager, Reference, Popper } from 'react-popper';

/* --- import menu data --- */
import { TopBarMenuData } from 'src/data/TopBarData';

/**
 * @type React.FunctionComponent<{}>
 */

// function isTop() {
//   return global.window ? window.pageYOffset < 80 : true;
// }

const Topbar = ({ currentCoursePage, isBannerClosed, is404 }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuItems = TopBarMenuData.map((section) => {
    return { [section]: false };
  });
  const [popper, setPopper] = useState(menuItems);
  const initPopper = (sectionToToggle, open) => {
    const popperMenu = { ...menuItems };
    if (sectionToToggle) {
      popperMenu[sectionToToggle] = open || false;
    }
    setPopper(popperMenu);
  };

  const mqs = useMediaQueries();
  const initialIsTop = true;
  const initialIsMobile = mqs.phoneOnly;

  function isTop() {
    if (global.window) {
      if (isBannerClosed) {
        if (window.pageYOffset < 80) {
          return true;
        } else return false;
      } else if (window.pageYOffset < 80 - sizes.topbar.banner) {
        return true;
      } else return false;
    } else {
      return false;
    }
  }

  // faire un autre anims pas de InitialisTop  => passer mqs.phoneOnly // Update seulement quand mqs.phoneOnly change
  // Avoir un useEffect qui va prendre en paramètre faire un animsbis .setstate qui va avoir comme dépendance le mqs.phoneOnly
  // initialisMobile = mqs.phoneOnly
  // dans le useEffect mettre que mqs.phoneOnly en dépendance
  const anims = useTrackedSpring(
    (isTop) => {
      return {
        background: isTop ? colors.purple : colors.white,
        height: isTop ? sizes.topbar.staticHeight : sizes.topbar.fixedHeight,
        logoOpacity: isTop ? 0 : 1,
        color: isTop ? colors.white : colors.purpleDark,
        boxShadow: isTop
          ? 'rgba(0, 0, 0, 0) 0px 0.2rem 0.5rem 0px'
          : 'rgba(0, 0, 0, 0.06) 0px 0.2rem 0.5rem 0px',
        top: isTop ? 110 : 70,
      };
    },
    initialIsTop,
    'white'
  );

  const animsBanner = useTrackedSpring(
    (isMobile) => ({
      height: isMobile ? 110 : 40,
      background: '#8A7DDE',
      top: isMobile ? 110 : 70,
    }),
    initialIsMobile,
    'white'
  );

  const previousOffset = React.useRef(global.window ? window.pageYOffset : 0);

  const update = () => {
    anims.setState(isTop());
    previousOffset.current = window.pageYOffset;
  };

  useEffect(() => {
    animsBanner.setState(mqs.phoneOnly);
  });

  useEffect(() => {
    const handleScroll = throttle(() => {
      update();
    }, 1000 / 60);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    update();
  });

  useEffect(() => {
    if ((mqs.tabletLandscapeUp || mqs.desktopUp || mqs.bigDesktopUp) && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [mqs.tabletLandscapeUp, mqs.desktopUp, mqs.bigDesktopUp, isMenuOpen]);

  return (
    <>
      <animated.div
        style={{
          background: anims.value.background,
          height: anims.value.height,
          boxShadow: anims.value.boxShadow,
        }}
        css={(theme) => ({
          position: 'fixed',
          zIndex: 2000,
          top:
            isBannerClosed || currentCoursePage === 'Alternance' || is404 ? 0 : sizes.topbar.banner,
          left: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        })}
      >
        <div
          css={{
            maxWidth: 1350,
            padding: '20px',
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Logo logoOpacity={anims.value.logoOpacity} />
          <div css={{ flex: 1, marginLeft: 10 }} />
          <Theme theme={anims.state ? 'primary' : 'light'}>
            <div
              css={{
                display: mqs.tabletLandscapeUp ? 'flex' : 'none',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {TopBarMenuData.map(({ section, elements, link, width, isActive }) => {
                if (isActive)
                  return (
                    <Manager key={section}>
                      <button
                        onMouseEnter={() => initPopper(section, true)}
                        onMouseLeave={() => initPopper(section)}
                      >
                        <Reference>
                          {({ ref }) =>
                            link ? (
                              <MenuItem to={link} ref={ref} withPadding={true}>
                                {section}
                              </MenuItem>
                            ) : (
                              <MenuItemNotLink ref={ref} withPadding={true}>
                                {section}
                              </MenuItemNotLink>
                            )
                          }
                        </Reference>
                        {popper[section] && elements?.length && (
                          <Popper placement="bottom">
                            {({ ref, style, placement }) => (
                              <animated.div
                                ref={ref}
                                style={style}
                                data-placement={placement}
                                css={{
                                  minWidth: 'fit-content',
                                  backgroundColor: colors.white,
                                  padding: '16px 16px 16px 16px',
                                  borderRadius: '5px',
                                  listStyle: 'none',
                                  boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.20)',
                                  '&:before': {
                                    position: 'absolute',
                                    content: '""',
                                    top: '-5px',
                                    left: '50%',
                                    width: '11px',
                                    height: '11px',
                                    backgroundColor: colors.white,
                                    transform: 'translateX(-50%) rotate(45deg)',
                                    borderRadius: '2px',
                                    borderTop: '1px solid #F1F1F1',
                                    borderLeft: '1px solid #F1F1F1',
                                  },
                                }}
                              >
                                {elements
                                  .filter((el) => el.isActive !== false)
                                  .map(({ url, title, isNew, subtitle }) => {
                                    return (
                                      <DropdownMenuItem
                                        key={url}
                                        url={url}
                                        title={title}
                                        isNew={isNew || false}
                                        subtitle={
                                          <span>
                                            {subtitle.map(({ item, isBold }) => {
                                              return (
                                                <span
                                                  key={item}
                                                  css={{
                                                    fontWeight: isBold && 'bold',
                                                  }}
                                                >
                                                  {item}
                                                </span>
                                              );
                                            })}
                                          </span>
                                        }
                                      />
                                    );
                                  })}
                              </animated.div>
                            )}
                          </Popper>
                        )}
                      </button>
                    </Manager>
                  );
                else {
                  return null;
                }
              })}
              <AnchorButton
                isSmall={true}
                to="/postuler"
                state={
                  currentCoursePage
                    ? { fromFormationsPage: { type: currentCoursePage } }
                    : undefined
                }
              >
                Postuler
              </AnchorButton>
            </div>
            <button
              css={{
                display: mqs.tabletLandscapeUp ? 'none' : 'block',
                height: 45,
                width: 55,
                position: 'relative',
                zIndex: 6,
              }}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <div
                id="hamburger"
                css={{
                  display: mqs.tabletLandscapeUp ? 'none' : 'flex',
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  width: '100%',
                  height: '100%',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  transform: 'translateY(-50%)',
                }}
              >
                {isMenuOpen ? (
                  <MenuOpened colorFill={colors.white} />
                ) : (
                  <MenuClosed colorFill={anims.value.color} />
                )}
              </div>
            </button>
          </Theme>
        </div>
        <HamburgerMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </animated.div>
    </>
  );
};

export default Topbar;
