/**
 * @type AppColors
 */
const colors = {
  black: '#000',
  white: '#fff',
  lightGrey: '#FAFAFC',
  purple: '#5C48D3',
  purpleDark: '#190D33',
  purpleVeryDark: '#1A0C32',
  transparentPurple: 'rgba(92, 72, 211, 0.1)',
  purpleLight: '#8A7DDE',
  purpleVeryLight: 'rgba(233,233,240,0.8)',
  yellow: '#FCC01A',
  midGrey: '#DDDFDF',
  darkGrey: '#696969',
  red: '#D0021B',
  brown: '#7F3E49',
};

export default colors;
