import React from 'react';
import LeftContent from './LeftContent';
import RightContent from './RightContent';
import { mediaQueries } from 'src/style/breakpoints';

/**
 * @type React.FunctionComponent<{ left?: string; right?: string; sizeLeft?: number, sizeRight?: number, reverse? boolean}>
 */

const TwoColumns = ({
  isCardMobile,
  left,
  right,
  sizeLeft,
  tabletRow,
  sizeRight,
  reverse,
  withoutSpace,
  center,
}) => {
  return (
    <div
      css={{
        flex: 1,
        display: 'flex',
        flexDirection: reverse ? 'column-reverse' : 'column',
        //
        // boxShadow: isCardMobile ? 'rgba(0, 0, 0, 0.07) 1px 2px 15px' : '',
        // borderRadius: isCardMobile ? 12 : 0,
        // overflow: isCardMobile ? 'hidden' : '',
        // textAlign: isCardMobile ? 'center' : '',
        // '& > div:nth-of-type(1) > div': { borderRadius: isCardMobile ? 0 : 5 },
        // '& > div:last-of-type': { padding: isCardMobile ? '0 30px 30px 30px' : 0 },
        [mediaQueries.phoneOnly]: {
          alignItems: center && tabletRow ? 'center' : 'initial',
        },
        [mediaQueries.tabletPortraitUp]: {
          alignItems: center && tabletRow ? 'center' : 'initial',
        },
        [mediaQueries.tabletLandscapeUp]: {
          flexDirection: tabletRow ? 'column' : 'row',
          alignItems: center ? 'center' : 'flex-start',
          //
          // boxShadow: 'none',
          // borderRadius: 0,
          // overflow: 'none',
          // textAlign: 'left',
          // '& > div:nth-of-type(1) > div': { borderRadius: 5 },
          // '& > div:last-of-type': { padding: 0 },
        },
        [mediaQueries.desktopUp]: {
          flexDirection: tabletRow ? 'row' : 'row',
        },
      }}
    >
      <LeftContent
        tabletRow={tabletRow}
        sizeLeft={sizeLeft}
        reverse={reverse}
        css={{ height: '100%' }}
      >
        {left}
      </LeftContent>
      {withoutSpace ? '' : <div css={{ width: 64 }} />}
      <RightContent sizeRight={sizeRight} reverse={reverse}>
        {right}
      </RightContent>
    </div>
  );
};

export default TwoColumns;
