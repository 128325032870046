import React from 'react';
import { useSpring } from 'react-spring';

export function useTrackedSpring(stateToProps, initialState, name) {
  const [state, setInternal] = React.useState(initialState);
  const [running, setRunning] = React.useState(false);

  const utils = {
    onRest: () => {
      setRunning(false);
    },
    onStart: () => {
      setRunning(true);
    },
  };

  // @ts-ignore
  const [spring, setSpring] = useSpring(() => {
    return {
      ...stateToProps(state),
      ...utils,
    };
  });

  const setState = val => {
    if (val === state) {
      return;
    }
    setInternal(val);
    setSpring(stateToProps(val));
  };

  return {
    value: spring,
    state,
    running,
    setState: val => setState(val),
  };
}
