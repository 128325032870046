import React from 'react';
import { animated } from 'react-spring';

const MenuClosed = ({ className = '', colorFill }) => {
  return (
    <svg className={className} width="24" height="19" xmlns="http://www.w3.org/2000/svg">
      <animated.path
        d="M22.8 8H1.2C.48 8 0 8.6 0 9.5S.48 11 1.2 11h21.6c.72 0 1.2-.6 1.2-1.5S23.52 8 22.8 8zM1.2 3h21.6c.72 0 1.2-.6 1.2-1.5S23.52 0 22.8 0H1.2C.48 0 0 .6 0 1.5S.48 3 1.2 3zM22.8 16H1.2c-.72 0-1.2.6-1.2 1.5S.48 19 1.2 19h21.6c.72 0 1.2-.6 1.2-1.5s-.48-1.5-1.2-1.5z"
        fillRule="evenodd"
        style={{ fill: colorFill }}
      />
    </svg>
  );
};
export default MenuClosed;
