import React, { useContext } from 'react';

import { LeReacteurContext } from '../Layout';
import { Link } from 'gatsby';
import { Sentence } from '../Typography';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';

const linkStyle = {
  fontSize: 16,
  lineHeight: '28px',
  padding: 0,
  color: colors.white,
  transitionDuration: '0.3s',
  transitionTimingFunction: 'ease-in-out',
  transitionDelay: '0s',
  textDecoration: 'none',
  letterSpacing: 0.4,
  [mediaQueries.phoneOnly]: {
    lineHeight: '24px',
    paddingBottom: 8,
  },
  [mediaQueries.tabletPortraitUp]: {
    lineHeight: '24px',
    paddingBottom: 8,
  },
  '&:hover': {
    color: colors.yellow,
  },
};

const isExternalLink = (url) => /^http/.test(url);
const FooterElement = ({ heading, item }) => {
  const { dtlNavigationClick } = useContext(LeReacteurContext);

  return (
    <div
      css={{
        marginTop: 30,

        '&:not(:last-child)': {
          paddingRight: 5,
        },
      }}
    >
      <Sentence
        css={{
          color: colors.yellow,
          fontSize: 12,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          paddingBottom: 5,
          letterSpacing: 1.5,
          [mediaQueries.phoneOnly]: {
            fontSize: 12,
            lineHeight: '24px',
          },
        }}
      >
        {heading}
      </Sentence>

      <div css={{ display: 'flex', flexDirection: 'column' }}>
        {item.map(({ text, title, url, isHidden }) => {
          return !isHidden ? (
            isExternalLink(url) ? (
              <a
                key={text}
                href={url}
                rel="noopener noreferrer"
                css={linkStyle}
                onClick={() => dtlNavigationClick('footer', text, url)}
                title={title || null}
              >
                {text}
              </a>
            ) : (
              <Link
                key={text}
                to={url}
                css={linkStyle}
                onClick={() => dtlNavigationClick('footer', text, url)}
              >
                {text}
              </Link>
            )
          ) : null;
        })}
      </div>
    </div>
  );
};

export default FooterElement;
