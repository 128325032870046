import React from 'react';
import Cookies from 'js-cookie';

/* --- import Components ----*/
import { SentenceSmall } from 'src/components/Typography';
import CloseIcon from 'src/components/Icons/Close';
import { StyledLinkSmall } from 'src/components/Typography';

/* --- import Utils ----*/
import { useMediaQueries } from '../../utils/MediaQueries';

/* --- import Styles ----*/
import colors from 'src/style/colors';

const TopBanner = ({ setIsBannerClose }) => {
  const mqs = useMediaQueries();

  const closeBanner = () => {
    setIsBannerClose(true);
    Cookies.set('bannerHidden', true, { expires: 30 });
  };

  return (
    <div
      css={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: 1100,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'row',
        background: colors.yellow,
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      {mqs.tabletLandscapeUp ? (
        <SentenceSmall
          css={{
            color: colors.purpleDark,
          }}
        >
          Nouveau ! Vous pouvez maintenant vous former en alternance avec Le Reacteur.{' '}
          <StyledLinkSmall to="/formation-alternance/concepteur-developpeur-applications">
            En savoir plus
          </StyledLinkSmall>
          .
        </SentenceSmall>
      ) : (
        <SentenceSmall
          css={{
            color: colors.purpleDark,
          }}
        >
          Nouveau ! Formation en alternance.{' '}
          <StyledLinkSmall to="/formation-alternance/concepteur-developpeur-applications">
            En savoir plus
          </StyledLinkSmall>
          .
        </SentenceSmall>
      )}
      <div
        onClick={() => closeBanner()}
        css={{
          position: 'absolute',
          right: 5,
        }}
      >
        <CloseIcon css={{ marginLeft: 20, cursor: 'pointer' }} color={colors.purpleDark} />
      </div>
    </div>
  );
};

export default TopBanner;
