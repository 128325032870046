import React, { useState, useEffect } from 'react';
import { mediaQueries } from 'src/style/breakpoints';
import Theme from '../Theme';
import CenteredContent from './CenteredContent';
import colors from 'src/style/colors';

const HeaderBanner = ({ children, theme }) => {
  const [width, setWidth] = useState();

  useEffect(() => {
    const handleResize = () => {
      if (window) {
        setWidth(window.innerWidth);
      } else {
        return undefined;
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Theme theme={theme}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: colors.lightGrey,
        }}
      >
        <div
          css={(theme) => ({
            backgroundColor: theme.backgroundColor,
            maxWidth: 1300,
            width: '100%',
            borderRadius: width <= 1315 ? 0 : 5,
            position: 'relative',
            boxShadow: '0 5px 15px rgba(0,0,0,.07)',
            [mediaQueries.phoneOnly]: {
              paddingTop: 0,
              width: '100vw',
            },
            [mediaQueries.desktopUp]: {
              marginTop: -140,
            },
          })}
        >
          <CenteredContent
            css={{
              [mediaQueries.desktopUp]: {
                padding: '0 68px',
              },
            }}
          >
            {children}
          </CenteredContent>
        </div>
      </div>
    </Theme>
  );
};

export default HeaderBanner;
