import 'resize-observer-polyfill';

import React, { createContext, useEffect, useState } from 'react';

// import CookieBanner from './CookieBanner';
import Cookies from 'js-cookie';
import { Footer } from '../Footer';
import { Global } from '@emotion/core';
import { Helmet } from 'react-helmet';
import { MediaQueriesProvider } from '../../utils/MediaQueries';
import { RestoreFormProvider } from '../../utils/RestoreForm';
import Theme from '../Theme';
import { Topbar } from '../Topbar';
import globalStyle from 'src/style/global';
import metaDefaults from '../../data/metaDefaults';
import { withPrefix } from 'gatsby';

/* --- import component --- */
import TopBanner from '../TopBanner';

/**
 * @type React.FunctionComponent<{ metaTitle?: string, metaDescription?: string, metaKeywords?: string, metaImage?: string, pathname: string }>
 */
export default function Layout({
  pathname,
  children,
  currentCoursePage,
  metaTitle = metaDefaults.title,
  metaDescription = metaDefaults.description,
  metaKeywords = metaDefaults.keywords,
  metaImage = metaDefaults.image,
  metaVideo = '',
  is404 = false,
  isBootcampPage,
  isPodcastPage,
  businessType,
  pageType,
  pageName,
  formStart,
  formType,
}) {
  // add trailing slash
  if (pathname !== '/') {
    if (pathname[pathname.length - 1] !== '/') {
      pathname += '/';
    }
  }
  const url = 'https://www.lereacteur.io' + pathname;
  const dtlCommon = {
    businessType,
    pageType,
    pageName,
  };

  function getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    //eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const [isBannerClosed, setIsBannerClose] = useState(true);

  useEffect(() => {
    const utmSource = getParameterByName('utm_source');
    if (utmSource) {
      Cookies.set('utm_source', utmSource, { expires: 30 });
    }

    const fbclid = getParameterByName('fbclid');
    if (fbclid) {
      Cookies.set('fbclid', fbclid, { expires: 30 });
    }

    if (!Cookies.get('referrer')) {
      Cookies.set('referrer', document.referrer, { expires: 30 });
    }

    const utmMedium = getParameterByName('utm_medium');
    if (utmMedium) {
      Cookies.set('utm_medium', utmMedium, { expires: 30 });
    }

    const utmCampaign = getParameterByName('utm_campaign');
    if (utmCampaign) {
      Cookies.set('utm_campaign', utmCampaign, { expires: 30 });
    }

    const utmCampaignId = getParameterByName('utm_campaign_id');
    if (utmCampaignId) {
      Cookies.set('utm_campaign_id', utmCampaignId, { expires: 30 });
    }

    const adGroupName = getParameterByName('adgroup_name');
    if (adGroupName) {
      Cookies.set('adgroup_name', adGroupName, { expires: 30 });
    }

    const utmAdGroupId = getParameterByName('utm_adgroup_id');
    if (utmAdGroupId) {
      Cookies.set('utm_adgroup_id', utmAdGroupId, { expires: 30 });
    }

    const utmTerm = getParameterByName('utm_term');
    if (utmTerm) {
      Cookies.set('utm_term', utmTerm, { expires: 30 });
    }

    const matchType = getParameterByName('matchtype');
    if (matchType) {
      Cookies.set('matchtype', matchType, { expires: 30 });
    }

    const utmContent = getParameterByName('utm_content');
    if (utmContent) {
      Cookies.set('utm_content', utmContent, { expires: 30 });
    }
    if (!Cookies.get('bannerHidden')) {
      setIsBannerClose(false);
    }
  });

  useEffect(() => {
    window.dataLayer.push(dtlCommon);
    if (formStart === true) {
      window.dataLayer.push({
        event: 'formStart',
        ...dtlCommon,
        type: formType,
      });
    }
    /* **** OLD CONFIG ****
      ReactGA.initialize('UA-82781234-1');
      ReactGA.set({
        page: pathname,
        // utm_source: getParameterByName('utm_source') || 'direct',
        // utm_medium: getParameterByName('utm_medium') || 'none',
        // utm_campaign: getParameterByName('utm_campaign') || 'none',
        // utm_term: getParameterByName('utm_term') || 'none',
        // utm_content: getParameterByName('utm_content') || 'none',
      });
      ReactGA.pageview(pathname);
      if (isBrowser) {
        const ReactPixel = require('react-facebook-pixel');
        try {
          ReactPixel.init('974149219417952', {}, { debug: false, autoConfig: true });
          ReactPixel.pageView();
        } catch (e) {}
      }
    */
  }, [pathname]);

  const dtlNavigationClick = (location, value, destination) => {
    window.dataLayer.push({
      event: 'navigationClick',
      ...dtlCommon,
      value,
      destination,
      location,
    });
  };
  const dtlToggleInteraction = (type, location, value) => {
    window.dataLayer.push({
      event: 'toggleInteraction',
      ...dtlCommon,
      type,
      value,
      location,
    });
  };
  const dtlFormValidation = (type) => {
    window.dataLayer.push({
      event: 'formValidate',
      ...dtlCommon,
      type,
    });
  };

  const dtlVirtualPageView = (type, stepNumber, stepName) => {
    window.dataLayer.push({
      event: 'virtualPageView',
      ...dtlCommon,
      stepNumber,
      stepName,
      type,
    });
  };

  return (
    <Theme>
      <MediaQueriesProvider>
        <RestoreFormProvider>
          <LeReacteurContext.Provider
            value={{
              dtlFormValidation,
              dtlNavigationClick,
              dtlToggleInteraction,
              dtlVirtualPageView,
            }}
          >
            <Global styles={globalStyle} />
            <Helmet title={metaTitle} defer={false}>
              <link
                rel="apple-touch-icon"
                sizes="57x57"
                href={withPrefix('/apple-icon-57x57.png')}
              />
              <link
                rel="apple-touch-icon"
                sizes="60x60"
                href={withPrefix('/apple-icon-60x60.png')}
              />
              <link
                rel="apple-touch-icon"
                sizes="72x72"
                href={withPrefix('/apple-icon-72x72.png')}
              />
              <link
                rel="apple-touch-icon"
                sizes="76x76"
                href={withPrefix('/apple-icon-76x76.png')}
              />
              <link
                rel="apple-touch-icon"
                sizes="114x114"
                href={withPrefix('/apple-icon-114x114.png')}
              />
              <link
                rel="apple-touch-icon"
                sizes="120x120"
                href={withPrefix('/apple-icon-120x120.png')}
              />
              <link
                rel="apple-touch-icon"
                sizes="144x144"
                href={withPrefix('/apple-icon-144x144.png')}
              />
              <link
                rel="apple-touch-icon"
                sizes="152x152"
                href={withPrefix('/apple-icon-152x152.png')}
              />
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={withPrefix('/apple-icon-180x180.png')}
              />
              <link
                rel="icon"
                type="image/png"
                sizes="192x192"
                href={withPrefix('/android-icon-192x192.png')}
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={withPrefix('/favicon-32x32.png')}
              />
              <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href={withPrefix('/favicon-96x96.png')}
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={withPrefix('/favicon-16x16.png')}
              />
              <link rel="manifest" href={withPrefix('/manifest.json')} />
              <meta name="msapplication-TileColor" content="#ffffff" />
              <meta name="msapplication-TileImage" content={withPrefix('/ms-icon-144x144.png')} />
              <meta name="theme-color" content="#ffffff" />
              <meta name="format-detection" content="telephone=no" />

              <meta name="description" content={metaDescription} />
              <meta name="keywords" content={metaKeywords} />
              <meta name="author" content="Le Reacteur" />

              <meta property="og:title" content={metaTitle} />
              <meta property="og:url" content={url} />
              <meta property="og:description" content={metaDescription} />
              <meta property="og:type" content="website" />
              <meta property="og:locale" content="fr_FR" />
              <meta property="og:site_name" content="Le Reacteur" />
              <meta property="og:image" content={metaImage} />
              <meta property="og:video" content={metaVideo} />

              {!is404 && <link rel="canonical" href={url} />}
              {isPodcastPage && (
                <link
                  rel="alternate"
                  type="application/rss+xml"
                  title="Podcast du Reacteur (RSS)"
                  href="/lereacteur-podcast/le-reacteur-podcast.xml"
                />
              )}
            </Helmet>
            {isBannerClosed || isBootcampPage || pageType === 'landingPage' || is404 ? null : (
              <TopBanner setIsBannerClose={setIsBannerClose} />
            )}
            {isBootcampPage || pageType === 'landingPage' ? null : (
              <Topbar
                currentCoursePage={currentCoursePage}
                is404={is404}
                isBannerClosed={isBannerClosed}
              />
            )}
            {children}

            {/* <CookieBanner /> */}

            <Footer pageName={pageName} pageType={pageType} />
          </LeReacteurContext.Provider>
        </RestoreFormProvider>
      </MediaQueriesProvider>
    </Theme>
  );
}
export const LeReacteurContext = createContext();
