import React from 'react';
import { breakpoints } from 'src/style/breakpoints';

export const MediaQueriesContext = React.createContext(null);

/**
 * @type React.FunctionComponent<{}>
 */
export const MediaQueriesProvider = ({ children }) => {
  const [mqs, setMqs] = React.useState(() => {
    const initialState = Object.keys(breakpoints).reduce((acc, key) => {
      // init as mobile
      acc[key] = key === 'phoneOnly' ? true : false;
      return acc;
    }, {});

    return initialState;
  });

  React.useEffect(() => {
    if (global.window) {
      const initialState = Object.keys(breakpoints).reduce((acc, key) => {
        acc[key] = window.matchMedia(breakpoints[key]).matches;
        return acc;
      }, {});
      setMqs(initialState);
    }
  }, []);

  React.useEffect(() => {
    const handlers = [];
    if (!global.window) {
      return () => {};
    }
    Object.keys(breakpoints).forEach(key => {
      const mq = window.matchMedia(breakpoints[key]);
      const handler = ev => {
        setMqs(prevState => {
          if (prevState[key] === ev.matches) {
            return prevState;
          }
          return {
            ...prevState,
            [key]: ev.matches,
          };
        });
      };
      mq.addListener(handler);
      handlers.push([mq, handler]);
    });

    return () => {
      handlers.forEach(item => {
        item[0].removeListener(item[1]);
      });
    };
  }, []);

  return <MediaQueriesContext.Provider value={mqs}>{children}</MediaQueriesContext.Provider>;
};

export function useMediaQueries() {
  const mqs = React.useContext(MediaQueriesContext);
  if (mqs === null) {
    throw new Error('No MediaQueriesContext, make sure you are using MediaQueriesProvider');
  }
  return mqs;
}
