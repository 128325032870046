import React from 'react';
import { mediaQueries } from 'src/style/breakpoints';

const LeftContent = ({ tabletRow, children, sizeLeft, reverse }) => {
  return (
    <div
      css={{
        marginBottom: reverse ? 0 : 32,
        flex: sizeLeft,
        [mediaQueries.phoneOnly]: {
          flex: 1,
        },
        [mediaQueries.tabletLandscapeUp]: {
          marginBottom: tabletRow ? 32 : 0,
        },
      }}
    >
      {children}
    </div>
  );
};

export default LeftContent;
