import React from 'react';

const Logo = ({ className = '' }) => {
  return (
    <svg className={className} viewBox="0 0 92 92" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M90.8315333 56.5369881C98.2732146 22.9686635 69.0308448-6.27817768 35.4634102 1.17026095 18.3546512 4.9871106 4.99235941 18.3463593 1.17194819 35.4539134-6.28362334 69.0274467 22.9743729 98.2847053 56.5470164 90.8275855 73.6552163 87.0076606 87.0149775 73.64553 90.8315333 56.5369881z"
          fill="#FFF"
        />
        <path
          d="M51.328338 59.3300521L76 49.1991893l-.4613778-6.239722-15.6472498 6.4273306c.1875699-.709252.3227956-1.431554.4045664-2.1609728l3.6359326-26.5292414-5.5830158-2.7237985-2.3120537 16.9056167c-.4380135-.441841-.9025651-.8560579-1.3910197-1.2403011L33.4241244 17l-5.1285243 3.522872 13.3455254 10.4661263c-.7051683.189719-1.395635.4312439-2.0658708.7226404L15 41.8042849l.4596563 6.2379849 15.6506928-6.4273306c-.1868137.704722-.3220336 1.4223478-.4045664 2.1470759l-3.6290463 26.5431383 5.5830158 2.7237985 2.3120537-16.9056166c.4375736.4423129.902155.8565564 1.3910197 1.2403011L57.5758756 74l5.1268027-3.522872-13.3472469-10.4678634c.6727107-.1790943 1.3319975-.406067 1.9729066-.6792125zm-5.8274772-22.6398379c4.8224108 0 8.7317472 3.9446643 8.7317472 8.8106544 0 4.86599-3.9093364 8.8106543-8.7317472 8.8106543-4.8224109 0-8.7317473-3.9446643-8.7317473-8.8106543.004745-4.8640055 3.9113033-8.8058665 8.7317473-8.8106544z"
          fill="#5C47D3"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default Logo;
