import styled from 'src/style';
import fonts from 'src/style/fonts';
import colors from 'src/style/colors';
import { Sentence, SentenceSmall } from '../Typography';
import { Link } from 'gatsby';
import isPropValid from '@emotion/is-prop-valid';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { mediaQueries } from 'src/style/breakpoints';

export const DropdownMenuSubTitle = styled.p({
  color: colors.purple,
  fontSize: 15,
  lineHeight: '22px',
  cursor: 'pointer',
});

export const DropdownMenuTitle = styled.p({
  color: colors.purpleDark,
  fontWeight: 'bold',
  fontSize: 17,
  cursor: 'pointer',
  lineHeight: '25px',
});

export const Pill = styled.span({
  backgroundColor: colors.yellow,
  marginLeft: 5,
  color: colors.white,
  fontSize: 10,
  borderRadius: 10,
  height: 20,
  padding: 5,
  paddingTop: 0,
  paddingBottom: 2,
  boxSizing: 'border-box',
  position: 'relative',
  top: -10,
});

export const MenuItem = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(
  {
    letterSpacing: '0.5px',
    cursor: 'pointer',
    transitionProperty: 'color',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-in-out',
    transitionDelay: '0s',
    textDecoration: 'none',
    [mediaQueries.tabletLandscapeUp]: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    '&:hover': { color: colors.yellow },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.bold,
  }),
  ({ mobile }) => ({
    fontSize: mobile ? 20 : 17,
    marginRight: mobile ? 0 : 35,
    lineHeight: mobile ? '40px' : '20px',
  })
);

export const MenuItemNotLink = styled(MenuItem.withComponent('div'))(({ withPadding }) => ({
  paddingTop: withPadding ? 15 : 'initial',
  paddingBottom: withPadding ? 15 : 'initial',
  //backgroundColor: 'red',
}));

export const HamburgerSubTitle = styled(Sentence)({
  ...fonts.HKCompakt.medium,
  color: colors.white,
  marginTop: 4,
  [mediaQueries.phoneOnly]: {
    fontSize: 16,
  },
});

export const SubMenuItem = styled(SentenceSmall.withComponent(AnchorLink), {
  shouldForwardProp: (prop) => isPropValid(prop),
})(
  {
    marginLeft: '32px',
    letterSpacing: 0.4,
    cursor: 'pointer',
    textDecoration: 'none',
    height: '100%',
    paddingTop: 13,
    '&:hover': {
      color: colors.purple,
      transition: 'all 0.2s ease-in-out 0s',
      borderBottom: `3px solid ${colors.purple}`,
      borderRadius: '-5px',
    },
  },
  ({ isSelectedTab, href, sectionScrolled }) => ({
    color:
      (isSelectedTab === href && sectionScrolled === false) || sectionScrolled === href
        ? colors.purple
        : colors.purpleDark,
    borderBottom:
      (isSelectedTab === href && sectionScrolled === false) || sectionScrolled === href
        ? `3px solid ${colors.purple}`
        : null,
  })
);
