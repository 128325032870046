import React from 'react';

/**
 * @type React.FunctionComponent<{}>
 */
const TopbarStaticSpace = () => {
  return <div css={theme => ({ height: theme.sizes.topbar.staticHeight })} />;
};

export default TopbarStaticSpace;
