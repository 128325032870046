import colors from './colors';

const themes = {
  light: {
    foregroundColor: colors.purpleDark,
    backgroundColor: colors.white,
  },
  primary: {
    foregroundColor: colors.white,
    backgroundColor: colors.purple,
  },
  secondary: {
    foregroundColor: colors.purpleDark,
    backgroundColor: colors.lightGrey,
  },
  ternary: {
    foregroundColor: colors.purpleDark,
    backgroundColor: colors.yellow,
  },
  dark: {
    foregroundColor: colors.white,
    backgroundColor: colors.purpleDark,
  },
};

export default themes;
