import styled from 'src/style';
import colors from './colors';
import { mediaQueries } from 'src/style/breakpoints';

export const Line = styled.div(
  {
    width: 26,
    height: 6,
    marginRight: 12,
    marginTop: 14,
    borderRadius: 50,
    [mediaQueries.phoneOnly]: {
      marginTop: 12,
    },
  },
  ({ purple }) => ({
    backgroundColor: purple ? colors.purple : colors.yellow,
  })
);

export const ThinLine = styled.div({
  width: 25,
  height: 5,
  backgroundColor: colors.yellow,
});
