import React from 'react';
import { Section } from '../Section';

import { useMediaQueries } from '../../utils/MediaQueries';
import { FooterLarge, FooterSmall, FooterLanding } from './index';

const Footer = ({ isBootcampPage, pageName, pageType }) => {
  const mqs = useMediaQueries();
  return (
    <Section
      theme="dark"
      landing={pageType === 'landingPage' ? true : false}
      css={{
        paddingTop: pageType !== 'landingPage' && 100,
        paddingBottom: pageType !== 'landingPage' && 40,
      }}
    >
      {pageType === 'landingPage' ? (
        <FooterLanding />
      ) : mqs.tabletPortraitUp ? (
        <FooterLarge pageName={pageName} />
      ) : (
        <FooterSmall isBootcampPage={isBootcampPage} pageName={pageName} />
      )}
    </Section>
  );
};
export default Footer;
