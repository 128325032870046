import React from 'react';
import { StyledBtn, StyledBtnSecondary } from './elements';

/**
 * @type React.FunctionComponent<{ className?: string, secondary?: boolean; small?: boolean }>
 */
const Button = ({
  children,
  className,
  secondary = false,
  isSmall = false,
  type = 'button',
  ...props
}) => {
  const StyledComp = secondary ? StyledBtnSecondary : StyledBtn;

  return (
    <StyledComp className={className} type={type} isSmall={isSmall} {...props}>
      <span>{children}</span>
      {/* {children} */}
    </StyledComp>
  );
};

export default Button;
