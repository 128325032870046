import React from 'react';
import { DropdownMenuTitle, DropdownMenuSubTitle, Pill } from './elements';
import colors from 'src/style/colors';
import { Link } from 'gatsby';

/**
 * @type React.FunctionComponent<{ title: string, subtitle: string, url: string, isNew: boolean}>
 */

const DropdownMenuItem = ({ title, subtitle, url, isNew }) => {
  return (
    <li
      css={{
        padding: '12px 18px 12px 18px',
        borderRadius: 5,
        '&:hover': { backgroundColor: colors.transparentPurple },
        transition: 'all 0.3s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s',
      }}
    >
      <Link to={url} css={{ textDecoration: 'none' }}>
        <DropdownMenuTitle>
          {title}
          {isNew ? <Pill>nouveau</Pill> : ``}
        </DropdownMenuTitle>
        <DropdownMenuSubTitle>{subtitle}</DropdownMenuSubTitle>
      </Link>
    </li>
  );
};

export default DropdownMenuItem;
