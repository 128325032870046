import styled from 'src/style';
import { Link } from 'gatsby';
import { mediaQueries } from 'src/style/breakpoints';
import colors from 'src/style/colors';

import AnchorLink from 'react-anchor-link-smooth-scroll';

export const HeaderTitle = styled.h1(
  {
    fontSize: 45,
    lineHeight: '55px',
    [mediaQueries.phoneOnly]: {
      fontSize: 40,
      lineHeight: '45px',
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.bold,
  })
);

export const SectionTitle = styled.h2(
  {
    fontSize: 45,
    lineHeight: '53px',
    display: 'inline-block',
    [mediaQueries.phoneOnly]: {
      fontSize: 33,
      lineHeight: '38px',
    },
  },
  ({ isMobileCenter, isCenter }) => ({
    [mediaQueries.phoneOnly]: {
      justifyContent: isMobileCenter || isCenter ? 'center' : 'flex-start',
      textAlign: isMobileCenter || isCenter ? 'center' : 'left',
    },
    [mediaQueries.tabletPortraitUp]: {
      justifyContent: isMobileCenter || isCenter ? 'center' : 'flex-start',
      textAlign: isMobileCenter || isCenter ? 'center' : 'left',
    },
    [mediaQueries.tabletLandscapeUp]: {
      justifyContent: isCenter ? 'center' : 'flex-start',
      textAlign: isCenter ? 'center' : 'left',
    },
  }),

  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.bold,
  })
);

export const SectionSubtitle = styled.span(
  {
    fontSize: 36,
    lineHeight: '46px',
    display: 'inline-block',
    paddingBottom: 12,
    [mediaQueries.phoneOnly]: {
      fontSize: '25px',
      lineHeight: '30px',
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.bold,
  })
);

export const BlogPostTitle = styled.h2(
  {
    fontSize: 23,
    lineHeight: '30px',
    display: 'inline-block',
    paddingBottom: 5,
    [mediaQueries.phoneOnly]: {
      fontSize: 19,
      lineHeight: '25px',
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.bold,
  })
);

export const TitleDescription = styled.h3(
  {
    fontSize: 30,
    lineHeight: '35px',
    paddingBottom: 4,
    display: 'inline-block',
    [mediaQueries.phoneOnly]: {
      fontSize: '25px',
      lineHeight: '30px',
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.bold,
  })
);

export const TitleDescriptionNonBold = styled.p(
  {
    fontSize: 25,
    lineHeight: '30px',
    paddingBottom: 4,
    [mediaQueries.phoneOnly]: {
      fontSize: '25px',
      lineHeight: '30px',
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
  })
);

export const EventDescription = styled.span(
  {
    fontSize: 30,
    lineHeight: '35px',
    paddingBottom: 4,
    display: 'inline-block',
    [mediaQueries.phoneOnly]: {
      fontSize: '25px',
      lineHeight: '30px',
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.bold,
  })
);

export const SubtitleDescription = styled.p(
  {
    fontSize: 21,
    lineHeight: '24px',
    paddingTop: 4,
    [mediaQueries.phoneOnly]: {
      fontSize: 19,
      lineHeight: '20px',
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.bold,
  })
);

export const CTASectionSubtitle = styled.span(
  {
    fontSize: 25,
    lineHeight: '35px',
    [mediaQueries.phoneOnly]: {
      fontSize: 20,
      lineHeight: '25px',
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.bold,
  })
);

export const HeaderDescription = styled.span(
  {
    fontSize: 24,
    lineHeight: '32px',
    [mediaQueries.phoneOnly]: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.medium,
  })
);

export const FormTitle = styled.span(
  {
    color: colors.purpleDark,
    textAlign: 'left',
    fontSize: 30,
    lineHeight: '35px',
    paddingBottom: 4,
    display: 'inline-block',
    fontWeight: 700,
    '&:after': {
      content: '""',
      display: 'inline-block',
      height: 10,
      width: 10,
      marginLeft: 3,
      backgroundColor: colors.yellow,
      borderRadius: '50%',
    },
    [mediaQueries.phoneOnly]: {
      fontSize: '28px',
      lineHeight: '35px',
      paddingBottom: 12,
      textAlign: 'center',
    },
  },
  ({ modal }) => ({
    fontSize: modal ? 28 : 30,
  })
);

export const FormSubtitle = styled.p({
  fontSize: 21,
  lineHeight: '24px',
  padding: '15px 0',
  fontWeight: 500,
  [mediaQueries.phoneOnly]: {
    fontSize: 19,
    lineHeight: '20px',
    textAlign: 'center',
  },
});

export const paragraphStyler = ({ theme }) => ({
  fontSize: 19,
  lineHeight: '28px',
  paddingTop: 20,
  paddingBottom: 20,
  textAlign: 'justify',
  [mediaQueries.phoneOnly]: {
    fontSize: 18,
    lineHeight: '25px',
  },
  color: theme.foregroundColor,
  ...theme.fonts.HKCompakt.medium,
});

export const TextContainer = styled.div(paragraphStyler);
export const Paragraph = styled.p(paragraphStyler);

export const paragraphNotJustifyStyler = ({ theme }) => ({
  fontSize: 19,
  lineHeight: '28px',
  paddingTop: 20,
  paddingBottom: 20,
  [mediaQueries.phoneOnly]: {
    fontSize: 18,
    lineHeight: '25px',
  },
  color: theme.foregroundColor,
  ...theme.fonts.HKCompakt.medium,
});

export const TextContainerNotJustify = styled.div(paragraphNotJustifyStyler);
export const ParagraphNotJustify = styled.p(paragraphNotJustifyStyler);

export const sentenceStyler = ({ theme, noSpacing }) => ({
  fontSize: 19,
  lineHeight: noSpacing ? '15px' : '25px',
  paddingBottom: noSpacing ? '5px' : '0px',
  [mediaQueries.phoneOnly]: {
    fontSize: 18,
    lineHeight: noSpacing ? '14px' : '24px',
    paddingBottom: noSpacing ? '4px' : '0px',
  },
  color: theme.foregroundColor,
  ...theme.fonts.HKCompakt.medium,
});

export const Sentence = styled.p(sentenceStyler);

export const SentenceSmall = styled.p(
  {
    fontSize: 15,
    lineHeight: '20px',
    [mediaQueries.phoneOnly]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.medium,
  })
);

export const InlineLink = styled(Link)(
  {
    fontSize: 19,
    lineHeight: '28px',
    textDecoration: 'none',
    color: colors.yellow,
    cursor: 'pointer',
    [mediaQueries.phoneOnly]: {
      fontSize: 18,
      lineHeight: '23px',
    },
  },
  ({ theme }) => ({
    ...theme.fonts.HKCompakt.bold,
  })
);

export const InlineLinkA = styled.a(
  {
    fontSize: 19,
    lineHeight: '28px',
    textDecoration: 'none',
    color: colors.yellow,
    cursor: 'pointer',
    [mediaQueries.phoneOnly]: {
      fontSize: 18,
      lineHeight: '23px',
    },
  },
  ({ theme }) => ({
    ...theme.fonts.HKCompakt.bold,
  })
);

export const StyledLink = styled(Link)(
  {
    fontSize: 19,
    lineHeight: '28px',
    textDecoration: 'none',
    color: colors.purple,
    cursor: 'pointer',
    [mediaQueries.phoneOnly]: {
      fontSize: 18,
      lineHeight: '23px',
    },
  },
  ({ theme }) => ({
    ...theme.fonts.HKCompakt.medium,
  })
);

export const StyledLinkMedium = styled(Link)(
  {
    fontSize: 16,
    lineHeight: '22px',
    textDecoration: 'none',
    color: colors.purple,
    cursor: 'pointer',
    [mediaQueries.phoneOnly]: {
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  ({ theme }) => ({
    ...theme.fonts.HKCompakt.medium,
  })
);

export const StyledLinkSmall = styled(Link)(
  {
    fontSize: 15,
    lineHeight: '20px',
    color: colors.purpleDark,
    cursor: 'pointer',
    [mediaQueries.phoneOnly]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  ({ theme }) => ({
    ...theme.fonts.HKCompakt.medium,
  })
);

export const StyledLinkA = styled.a(
  {
    fontSize: 19,
    lineHeight: '28px',
    textDecoration: 'none',
    cursor: 'pointer',
    [mediaQueries.phoneOnly]: {
      fontSize: 18,
      lineHeight: '23px',
    },
  },
  ({ theme }) => ({
    ...theme.fonts.HKCompakt.medium,
  }),
  ({ color }) => ({
    color: color,
  })
);

export const StyledLinkNoURL = styled.span(
  {
    fontSize: 19,
    lineHeight: '28px',
    textDecoration: 'none',
    color: colors.purple,
    cursor: 'pointer',
    [mediaQueries.phoneOnly]: {
      fontSize: 18,
      lineHeight: '23px',
    },
  },
  ({ theme }) => ({
    ...theme.fonts.HKCompakt.medium,
  })
);

export const StyledSmoothAnchorLink = styled(AnchorLink)(
  {
    fontSize: 19,
    lineHeight: '28px',
    textDecoration: 'none',
    color: colors.purple,
    cursor: 'pointer',
    [mediaQueries.phoneOnly]: {
      fontSize: 18,
      lineHeight: '23px',
    },
  },
  ({ theme }) => ({
    ...theme.fonts.HKCompakt.medium,
  })
);

export const UppercaseWords = styled.p(
  {
    fontSize: 13,
    lineHeight: '20px',
    paddingBottom: 5,
    textTransform: 'uppercase',
    letterSpacing: 1.2,
    [mediaQueries.phoneOnly]: {
      fontSize: 11,
    },
  },
  ({ theme }) => ({
    color: theme.foregroundColor,
    ...theme.fonts.HKCompakt.bold,
  })
);

export const H1 = styled.h1({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  display: 'inline',
  marginBlockStart: 0,
  marginBlockEnd: 0,
  marginInlineStart: 0,
  marginInlineEnd: 0,
});

export const H2 = styled.h2({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  display: 'inline',
  marginBlockStart: 0,
  marginBlockEnd: 0,
  marginInlineStart: 0,
  marginInlineEnd: 0,
});

export const H3 = styled.h3({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  display: 'inline',
  marginBlockStart: 0,
  marginBlockEnd: 0,
  marginInlineStart: 0,
  marginInlineEnd: 0,
});

export const H4 = styled.h4({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  display: 'inline',
  marginBlockStart: 0,
  marginBlockEnd: 0,
  marginInlineStart: 0,
  marginInlineEnd: 0,
});

export const H5 = styled.h5({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  display: 'inline',
  marginBlockStart: 0,
  marginBlockEnd: 0,
  marginInlineStart: 0,
  marginInlineEnd: 0,
});

export const H6 = styled.h6({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  display: 'inline',
  marginBlockStart: 0,
  marginBlockEnd: 0,
  marginInlineStart: 0,
  marginInlineEnd: 0,
});
