import React from 'react';
import { HamburgerSubTitle, MenuItem, Pill } from './elements';
import { ArrowLeft } from '../Icons/arrows';
import { useMediaQueries } from '../../utils/MediaQueries';
import fonts from 'src/style/fonts';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';

/* --- import menu data --- */
import { TopBarMenuData } from 'src/data/TopBarData';

const HamburgerMenuBootcamp = ({ showFormations, setShowFormations, onClick }) => {
  const mqs = useMediaQueries();
  return (
    <div
      css={{
        position: 'absolute',
        top: '50%',
        opacity: showFormations ? 1 : 0,
        visibility: showFormations ? 'visible' : 'hidden',
        transform: 'translateY(-50%)',
        width: '100vw',
      }}
    >
      <button
        css={{
          ...fonts.HKCompakt.bold,
          color: colors.yellow,
          fontSize: '20px',
          textAlign: 'left',
          paddingLeft: '15%',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          height: 44,
          [mediaQueries.phoneOnly]: {
            paddingLeft: '10%',
          },
        }}
        onClick={() => setShowFormations(false)}
      >
        <ArrowLeft color={colors.yellow} mqs={mqs} />
        <span css={{ marginLeft: '8px' }}> Formations</span>
      </button>

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 35,
          padding: '0 15%',
          textAlign: 'left',
          [mediaQueries.phoneOnly]: {
            padding: '0 10%',
          },
        }}
      >
        {TopBarMenuData.find((el) => el.section === 'Formations').elements.map(
          ({ url, title, subtitle, isActive, isNew }) => {
            if (isActive) {
              return (
                <div key={url} css={{ textDecoration: 'none', lineHeight: '28px', marginTop: 10 }}>
                  <MenuItem onClick={onClick} to={url}>
                    {title}
                    {isNew ? <Pill>nouveau</Pill> : ''}
                    <HamburgerSubTitle>
                      <span>
                        {subtitle.map(({ item, isBold }) => {
                          return (
                            <span key={item} css={{ fontWeight: isBold && 'bold' }}>
                              {item}
                            </span>
                          );
                        })}
                      </span>
                    </HamburgerSubTitle>
                  </MenuItem>
                </div>
              );
            } else {
              return null;
            }
          }
        )}
      </div>
    </div>
  );
};

export default HamburgerMenuBootcamp;
