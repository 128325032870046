import React from 'react';

const TwoSectionBG = ({ colorRight, colorLeft, isSessionName }) => {
  return (
    <div
      css={{
        position: 'absolute',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        display: 'flex',
        alignItems: 'stretch',
      }}
    >
      {isSessionName && <div css={{ backgroundColor: colorLeft, flex: 1 }} />}
      <div css={{ backgroundColor: colorRight, flex: 1 }} />
    </div>
  );
};
export default TwoSectionBG;
