/**
 * @type AppFonts
 */
const fonts = {
  HKCompakt: {
    medium: {
      fontFamily: 'HK Compakt',
      fontStyle: 'normal',
      fontWeight: 500,
    },
    bold: {
      fontFamily: 'HK Compakt',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    regular: {
      fontFamily: 'HK Compakt',
      fontStyle: 'normal',
      fontWeight: 400,
    },
  },
};

export default fonts;
