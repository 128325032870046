import CenteredContent from './CenteredContent';
import React from 'react';
import Theme from '../Theme';
import { mediaQueries } from 'src/style/breakpoints';

/**
 * @type React.FunctionComponent<{ theme?: string; className?: string; hero?: boolean, withoutCenteredContent?: boolean }>
 */
const Section = React.forwardRef(
  (
    {
      children,
      theme,
      id,
      hidden,
      className = '',
      hero = false,
      withoutCenteredContent,
      home,
      landing,
      margin,
    },
    ref
  ) =>
    hidden ? null : (
      <Theme theme={theme || 'light'}>
        <div
          id={id ? id : ''}
          ref={ref ? ref : null}
          className={className}
          css={(theme) => ({
            backgroundColor: theme.backgroundColor,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            paddingTop: landing & hero ? 50 : 110,
            paddingBottom: 110,
            [mediaQueries.phoneOnly]: {
              paddingTop: 55,
              paddingBottom: 55,
            },
            [mediaQueries.desktopUp]: {
              paddingLeft: landing && hero ? 30 : null,
              paddingRight: landing && hero ? 30 : null,
              paddingTop: landing & hero ? 50 : hero && !home ? 20 : home ? 0 : 110,
              paddingBottom: hero && !home ? null : home ? 60 : hero ? 50 : 110,
              margin: margin ? '50px 0 110px 0' : null,
            },
          })}
        >
          {withoutCenteredContent ? (
            <div>{children}</div>
          ) : (
            <CenteredContent hero={hero} landing={landing}>
              {children}
            </CenteredContent>
          )}
        </div>
      </Theme>
    )
);

/* const Section = ({
  children,
  theme,
  id,
  hidden,
  className = '',
  hero = false,
  withoutCenteredContent,
  home,
}) => {
  return hidden ? null : (
    <Theme theme={theme}>
      <div
        id={id ? id : ''}
        //ref={containeRef ? containeRef : ''}
        className={className}
        css={theme => ({
          backgroundColor: theme.backgroundColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          paddingTop: 110,
          paddingBottom: 110,
          [mediaQueries.phoneOnly]: {
            paddingTop: 55,
            paddingBottom: 55,
          },
          [mediaQueries.desktopUp]: {
            paddingTop: hero && !home ? 70 : home ? 0 : 110,
            paddingBottom: hero ? null : 110,
          },
        })}
      >
        {withoutCenteredContent ? (
          <div>{children}</div>
        ) : (
          <CenteredContent hero={hero}>{children}</CenteredContent>
        )}
      </div>
    </Theme>
  );
}; */

export default Section;
