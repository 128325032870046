import React from 'react';

const isBrowser = typeof window !== 'undefined';

function createFormDataManager() {
  const data = {};

  function set(name, formData) {
    data[name] = formData;
  }

  function get(name) {
    return data[name];
  }

  function reset(name) {
    data[name] = undefined;
  }

  return {
    get,
    set,
    reset,
    __data: data,
  };
}

const RestoreFormContext = React.createContext(null);

export const RestoreFormProvider = ({ children }) => {
  const [manager] = React.useState(() => {
    if (isBrowser && window.FORM_DATA_MANAGER) {
      return window.FORM_DATA_MANAGER;
    }
    const manager = createFormDataManager();
    if (isBrowser) {
      window.FORM_DATA_MANAGER = manager;
    }
    return manager;
  });

  return <RestoreFormContext.Provider value={manager}>{children}</RestoreFormContext.Provider>;
};

export const useRestoreFormManager = () => {
  const manager = React.useContext(RestoreFormContext);
  if (manager === null) {
    throw new Error('Missing RestoreFormProvider');
  }
  return manager;
};
