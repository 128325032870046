import React, { useState, useEffect } from 'react';
import Theme from '../Theme';
import CenteredContent from './CenteredContent';
import { mediaQueries } from 'src/style/breakpoints';

/**
 * @type React.FunctionComponent<{ theme?: string; className?: string; hero?: boolean, marginTop?: number, FAQSection?: boolean }>
 */
const SectionOverlap = ({
  children,
  theme,
  className = '',
  marginTop,
  hero = false,
  childrenIsTabs,
}) => {
  const [width, setWidth] = useState();

  useEffect(() => {
    const handleResize = () => {
      if (window) {
        setWidth(window.innerWidth);
      } else {
        return undefined;
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Theme theme={theme}>
      <div
        className={className}
        css={(theme) => ({
          backgroundColor: theme.backgroundColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        })}
      >
        <div
          css={(theme) => ({
            backgroundColor: theme.backgroundColor,
            marginTop: 0,
            paddingTop: childrenIsTabs ? 0 : 8,
            paddingBottom: 110,
            maxWidth: 1220,
            width: width > 1220 ? 1220 : '100vw',
            borderRadius: width <= 1315 ? 0 : 5,
            position: 'relative',
            [mediaQueries.phoneOnly]: {
              paddingTop: 0,
              paddingBottom: 55,
              width: '100vw',
            },
            [mediaQueries.desktopUp]: {
              marginTop: marginTop,
            },
          })}
        >
          <CenteredContent
            hero={hero}
            css={{
              [mediaQueries.desktopUp]: {
                padding: childrenIsTabs ? '0 0' : '0 68px',
              },
            }}
          >
            {children}
          </CenteredContent>
        </div>
      </div>
    </Theme>
  );
};

export default SectionOverlap;
