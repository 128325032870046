import React from 'react';
import { AnchorButton } from 'src/components/Button';
import { mediaQueries } from 'src/style/breakpoints';
import colors from 'src/style/colors';

const MobileApplyBTN = React.memo(({ buttonVisible, currentCoursePage, certification }) => {
  return (
    <AnchorButton
      css={{
        [mediaQueries.phoneOnly]: {
          position: 'fixed',
          bottom: 0,
          zIndex: 4,
          width: '100%',
          display: 'block',
          borderRadius: 0,
          backgroundColor: colors.yellow,
          color: colors.purpleDark,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px -2px 20px',
          transform: buttonVisible ? 'translateY(0)' : 'translateY(150%)',
          transition: 'all 0.4s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s',
          '&:before': {
            display: 'none',
          },
          '&:hover': {
            transform: 'none',
          },
        },
        display: 'none',
      }}
      to="/postuler"
      state={
        currentCoursePage
          ? { fromFormationsPage: { certification: certification, type: currentCoursePage } }
          : undefined
      }
    >
      Postuler
    </AnchorButton>
  );
});
export default MobileApplyBTN;
