import React from 'react';
import { animated } from 'react-spring';

const MenuOpened = ({ className = '', colorFill, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="19"
      height="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <animated.path
        d="M11.4 9.5l7.1928571-7.19285714c.5428572-.54285715.5428572-1.35714286 0-1.9-.5428571-.54285715-1.3571428-.54285715-1.9 0L9.5 7.6 2.30714286.40714286c-.54285715-.54285715-1.35714286-.54285715-1.9 0-.54285715.54285714-.54285715 1.35714285 0 1.9L7.6 9.5.40714286 16.6928571c-.54285715.5428572-.54285715 1.3571429 0 1.9C.67857143 18.8642857.95 19 1.35714286 19c.40714285 0 .67857143-.1357143.95-.4071429L9.5 11.4l7.1928571 7.1928571c.2714286.2714286.6785715.4071429.95.4071429.2714286 0 .6785715-.1357143.95-.4071429.5428572-.5428571.5428572-1.3571428 0-1.9L11.4 9.5z"
        fillRule="evenodd"
        style={{ fill: colorFill }}
      />
    </svg>
  );
};
export default MenuOpened;
