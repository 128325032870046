import React from 'react';
/* --- import Components ----*/
import { SentenceSmall } from '../Typography';
/* --- import Styles and Images ----*/
import LogoLeReacteur from 'src/components/Logo/LogoLeReacteurFooter';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';
import { useMediaQueries } from 'src/utils/MediaQueries';

const date = new Date();

const FooterLanding = () => {
  const mqs = useMediaQueries();
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <LogoLeReacteur css={{ width: 75 }} />
      <SentenceSmall
        css={{
          fontSize: 14,
          textAlign: 'end',
          whiteSpace: 'pre-wrap',
          [mediaQueries.tabletLandscapeUp]: {
            whiteSpace: 'normal',
          },
        }}
      >
        {`${date.getFullYear()} © Le Reacteur${
          mqs.tabletLandscapeUp ? ' | ' : `\n`
        }Tous droits réservés`}
        {mqs.tabletLandscapeUp ? ' | ' : `\n`}
        <a
          css={{ textDecoration: 'none', color: colors.white }}
          href="http://lereacteur.io"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          www.lereacteur.io
        </a>
      </SentenceSmall>
    </div>
  );
};
export default FooterLanding;
