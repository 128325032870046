import React from 'react';

const CloseIcon = ({ className = '', color }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12">
      <g fill="none" fillRule="evenodd">
        {/* <path fill="#5C48D3" d="M-1349-111H91v933h-1440z"></path> */}
        {/* <path fill="#8A7DDE" d="M-1349-17H91v48h-1440z"></path> */}
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M11 1L1 11M1 1l10 10"
        ></path>
      </g>
    </svg>
  );
};

export default CloseIcon;
