import { MenuItem, MenuItemNotLink } from './elements';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { AnchorButton } from '../Button';
import HamburgerMenuBootcamp from './HamburgerMenuBootcamp';
import HamburgerMenuEnterprisePage from './HamburgerMenuEnterprisePage';
import { ThinLine } from 'src/style/lines';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';

/**
 * @type React.FunctionComponent<{ isMenuOpen?: boolean}>
 */

const HamburgerMenu = ({ isMenuOpen, setIsMenuOpen }) => {
  const [showEntreprise, setShowEnterprise] = useState(false);
  const [showFormations, setShowFormations] = useState(false);

  useEffect(() => {
    if (!isMenuOpen) {
      setShowEnterprise(false);
      setShowFormations(false);
    }
  }, [isMenuOpen]);

  useLayoutEffect(() => {
    if (isMenuOpen) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = 'hidden';
      return () => (document.body.style.overflow = originalStyle);
    }
  });

  return (
    isMenuOpen && (
      <div
        id="hamburgerMenu"
        css={{
          display: 'block',
          backgroundColor: colors.purpleDark,
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          zIndex: 5,
          top: 0,
          overflow: 'auto',
        }}
      >
        <div
          css={{
            listStyle: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: '100vh',
            transform: (showFormations || showEntreprise) && 'translateX(-100%)',
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              height: '50%',
            }}
          >
            {/* {TopBarMenuData.find((el) => el.section === 'Formations').elements.map(
              ({ url, title, subtitle, isActive }, index) => {
                if (isActive)
                  return (
                    <div
                      key={index}
                      css={{ textDecoration: 'none', lineHeight: '28px', marginTop: 10 }}
                    >
                      <MenuItem onClick={onClick} to={url}>
                        {title}
                        <HamburgerSubTitle>
                          {' '}
                          <span>
                            {subtitle.map(({ item, isBold }) => {
                              if (isBold) {
                                return <span css={{ fontWeight: 'bold' }}>{item}</span>;
                              }
                              return item;
                            })}
                          </span>
                        </HamburgerSubTitle>
                      </MenuItem>
                    </div>
                  );
              }
            )} */}
            <MenuItemNotLink mobile={true} onClick={() => setShowFormations(true)}>
              Formations
            </MenuItemNotLink>
            <ThinLine />
            <MenuItemNotLink
              mobile={true}
              onClick={() => {
                setShowEnterprise(true);
              }}
            >
              Entreprises
            </MenuItemNotLink>
            <ThinLine />
            <MenuItem to="/recruteurs/" mobile={true}>
              Recruteurs
            </MenuItem>
            <ThinLine />
            <MenuItem to="/evenements/" mobile={true}>
              Événements
            </MenuItem>
            <ThinLine />
            <MenuItem to="/blog/" mobile={true}>
              Blog
            </MenuItem>
          </div>

          <div
            css={{
              [mediaQueries.phoneOnly]: {
                width: '80%',
              },
            }}
          >
            <AnchorButton
              to="/postuler/"
              onClick={() => {
                setIsMenuOpen(false);
              }}
            >
              Postuler
            </AnchorButton>
          </div>
        </div>
        <HamburgerMenuEnterprisePage
          setShowEnterprise={setShowEnterprise}
          showEntreprise={showEntreprise}
          onClick={() => setIsMenuOpen(false)}
        />
        <HamburgerMenuBootcamp
          setShowFormations={setShowFormations}
          showFormations={showFormations}
          onClick={() => setIsMenuOpen(false)}
        />
      </div>
    )
  );
};

export default HamburgerMenu;
