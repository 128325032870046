import 'ress';
import './fonts/hkcompakt.css';
import colors from './colors';

export default {
  'html, body': {
    fontFamily: `"HK Compakt"`,
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    fontSmoothing: 'antialiased',
    backgroundColor: colors.purple,
    color: colors.lightGrey,
    overflowY: 'initial',
    overflowX: 'hidden',
    overflow: 'initial',
  },
  body: {
    overflowX: 'hidden',
  },
  '*': {
    outline: 'none',
  },
  '#program li': {
    marginTop: 20,
    marginLeft: 20,
  },
};
