import React from 'react';
import { StyledAnchor, StyledAnchorSecondary } from './elements';

/**
 * @type React.FunctionComponent<{ className?: string; secondary?: boolean; to: string; issmall?: boolean}>
 */
const AnchorButton = ({ children, state, className, secondary = false, to, isSmall = false }) => {
  const StyledComp = secondary ? StyledAnchorSecondary : StyledAnchor;

  return (
    <StyledComp className={className} state={state} isSmall={isSmall} to={to} data-cy="anchorBtn">
      <span>{children}</span>
    </StyledComp>
  );
};

export default AnchorButton;
