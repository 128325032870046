import styled from 'src/style';
import { mediaQueries } from 'src/style/breakpoints';

const CenteredContent = styled.div((props) => ({
  margin: '0 auto',
  maxWidth: 1350,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 'auto',
  [mediaQueries.phoneOnly]: {
    padding: props.hero === true ? '0 20px' : '0 20px',
  },
  [mediaQueries.tabletPortraitUp]: {
    padding: props.hero === true ? '0 20px' : '0 40px',
  },
  [mediaQueries.desktopUp]: {
    padding: props.hero === true ? 'auto' : props.landing === true ? '0 50px' : '0 100px',
  },
}));
export default CenteredContent;
