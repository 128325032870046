import React from 'react';
import datadock from '../../data/images/institutionals/datadock.png';
import defimetiers from '../../data/images/institutionals/defimetiers.png';
import iledefrance from '../../data/images/institutionals/iledefrance.png';
import numeum from '../../data/images/institutionals/numeum.png';
import frenchtech from '../../data/images/institutionals/frenchtech.png';
import villeparis from '../../data/images/institutionals/ville-de-paris.png';
import { mediaQueries } from 'src/style/breakpoints';

const LogoPartners = () => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
        flex: 1,
        [mediaQueries.desktopUp]: {
          justifyContent: 'space-between',
        },
        [mediaQueries.phoneOnly]: {
          justifyContent: 'space-evenly',
          '& >img': { margin: '0 20px 15px 20px' },
        },
      }}
    >
      <img width="auto" height={35} src={villeparis} alt="Logo Ville de Paris" />
      <img width="auto" height={20} src={iledefrance} alt="Logo Île de France" />
      <img width="auto" height={35} src={numeum} alt="Logo Numeum" />
      <img width="auto" height={43} src={datadock} alt="Logo Datadock" />
      <img width="auto" height={35} src={defimetiers} alt="Logo Défi Métiers" />
      <img width="auto" height={35} src={frenchtech} alt="Logo La French Tech" />
      {/* <img width="auto" height={35} src={qualiopi} alt="Logo Qualiopi" /> */}
    </div>
  );
};
export default LogoPartners;
