import React from 'react';
import { HamburgerSubTitle, MenuItem, Pill } from './elements';
import { ArrowLeft, ArrowRight } from '../Icons/arrows';
import { useMediaQueries } from '../../utils/MediaQueries';
import fonts from 'src/style/fonts';
import colors from 'src/style/colors';

/* --- import menu data --- */
import { TopBarMenuData } from 'src/data/TopBarData';

const HamburgerMenuEnterprisePage = ({ showEntreprise, setShowEnterprise, onClick }) => {
  const mqs = useMediaQueries();
  return (
    <div
      css={{
        position: 'absolute',
        top: '50%',
        opacity: showEntreprise ? 1 : 0,
        visibility: showEntreprise ? 'visible' : 'hidden',
        transform: 'translateY(-50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'justify',
        width: '100vw',
      }}
    >
      <button
        css={{
          ...fonts.HKCompakt.bold,
          color: colors.yellow,
          fontSize: '20px',
          textAlign: 'left',
          paddingLeft: '15%',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          height: 44,
        }}
        onClick={() => setShowEnterprise(false)}
      >
        <ArrowLeft color={colors.yellow} mqs={mqs} />
        <span css={{ marginLeft: '8px' }}> Entreprises</span>
      </button>

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '35px',
          padding: '0 15%',
          textAlign: 'left',
        }}
      >
        {TopBarMenuData.find((el) => el.section === 'Entreprises').elements.map(
          ({ url, title, subtitle, isActive, isNew }) => {
            if (isActive) {
              return (
                <div
                  key={title}
                  css={{ textDecoration: 'none', lineHeight: '28px', marginTop: 10 }}
                >
                  <MenuItem onClick={onClick} to={url}>
                    {title}
                    {isNew ? <Pill>nouveau</Pill> : ''}
                    <br />{' '}
                    <HamburgerSubTitle>
                      <span>
                        {subtitle.map(({ item, isBold }) => {
                          return (
                            <span key={item} css={{ fontWeight: isBold && 'bold' }}>
                              {item}
                            </span>
                          );
                        })}
                      </span>
                    </HamburgerSubTitle>
                  </MenuItem>
                </div>
              );
            } else return null;
          }
        )}
        <div
          css={{
            textDecoration: 'none',
            lineHeight: '28px',
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div css={{ marginTop: -1.5, marginRight: 10 }}>
            <ArrowRight mqs={mqs} colors={colors.white} />
          </div>

          <MenuItem onClick={onClick} to="/formations-professionnelles/">
            Voir toutes les formations
          </MenuItem>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenuEnterprisePage;
