import React from 'react';
import { withPrefix } from 'gatsby';
import { Link } from 'gatsby';
import { animated } from 'react-spring';

const Logo = ({ logoOpacity }) => (
  <Link css={{ position: 'relative' }} to="/">
    <img
      css={{
        height: 37,
        zIndex: 2,
        opacity: 1,
        position: 'relative',
      }}
      src={withPrefix('/logo-le-reacteur.png')}
      alt="Le Reacteur - Logo"
    />

    <animated.img
      css={{
        height: 37,
        zIndex: 3,
        left: 0,
        position: 'absolute',
      }}
      style={{ opacity: logoOpacity }}
      src={withPrefix('/logo-le-reacteur-2.png')}
      alt="Le Reacteur - Logo"
    />
  </Link>
);

export default Logo;
