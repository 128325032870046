import React from 'react';
import colors from 'src/style/colors';

export const ArrowLeft = React.forwardRef(({ className, mqs, color = colors.lightGrey }, ref) => {
  return (
    <div className={className} mqs={mqs} ref={ref}>
      {mqs.tabletLandscapeUp ? (
        <svg width="19" height="31" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.7 15.5L18.2875 3.76428571c.95-.88571428.95-2.21428571 0-3.1-.95-.88571428-2.375-.88571428-3.325 0L.7125 13.95c-.95.8857143-.95 2.2142857 0 3.1l14.25 13.2857143C15.4375 30.7785714 16.15 31 16.625 31c.475 0 1.1875-.2214286 1.6625-.6642857.95-.8857143.95-2.2142857 0-3.1L5.7 15.5z"
            fill={color}
            fillRule="evenodd"
          />
        </svg>
      ) : (
        <svg width="7" height="13" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.1 6.3l4.6375-4.77c.35-.36.35-.9 0-1.26s-.875-.36-1.225 0l-5.25 5.4c-.35.36-.35.9 0 1.26l5.25 5.4c.175.18.4375.27.6125.27.175 0 .4375-.09.6125-.27.35-.36.35-.9 0-1.26L2.1 6.3z"
            fill={color}
            fillRule="evenodd"
          />
        </svg>
      )}
    </div>
  );
});

export const ArrowRight = React.forwardRef(({ className, mqs, color = colors.lightGrey }, ref) => {
  return (
    <div className={className} mqs={mqs} ref={ref}>
      {mqs.tabletLandscapeUp ? (
        <svg width="19" height="31" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.2875 14.0823529L4.0375.67058824c-.95-.89411765-2.375-.89411765-3.325 0-.95.89411764-.95 2.23529411 0 3.12941176L13.3 15.6470588.7125 27.4941176c-.95.8941177-.95 2.2352942 0 3.1294118.475.4470588.95.6705882 1.6625.6705882.7125 0 1.1875-.2235294 1.6625-.6705882l14.25-13.4117647c.95-.8941176.95-2.2352941 0-3.1294118z"
            fill={color}
            fillRule="evenodd"
          />
        </svg>
      ) : (
        <svg width="7" height="13" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.7375 5.67l-5.25-5.4c-.35-.36-.875-.36-1.225 0-.35.36-.35.9 0 1.26L4.9 6.3.2625 11.07c-.35.36-.35.9 0 1.26.175.18.35.27.6125.27.2625 0 .4375-.09.6125-.27l5.25-5.4c.35-.36.35-.9 0-1.26z"
            fill={color}
            fillRule="evenodd"
          />
        </svg>
      )}
    </div>
  );
});

export const ArrowPurple = ({ className }) => {
  return (
    <svg className={className} width="11" height="17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5875 7.65L2.3375.36428571c-.55-.48571428-1.375-.48571428-1.925 0-.55.48571429-.55 1.21428572 0 1.7L7.7 8.5.4125 14.9357143c-.55.4857143-.55 1.2142857 0 1.7C.6875 16.8785714.9625 17 1.375 17s.6875-.1214286.9625-.3642857L10.5875 9.35c.55-.48571429.55-1.21428571 0-1.7z"
        fill="#5C48D3"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ArrowColored = ({ className, color }) => {
  return (
    <svg className={className} width="10" height="14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.595956 3.41381l3.647463 3.58606-3.647463 3.58607c-.794608.78101-.794608 2.04704 0 2.82805C.992751 13.805 1.513672 14 2.034592 14c.520921 0 1.041842-.195 1.438637-.58601L10 6.99987 3.473229.58576c-.79359-.78101-2.083683-.78101-2.877273 0-.794608.78101-.794608 2.04704 0 2.82805z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ChevronRight = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#5C48D3"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  );
};

export const ChevronDown = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#5C48D3"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};
