import styled from 'src/style';
import colors from 'src/style/colors';
import { Link } from 'gatsby';
import { mediaQueries } from 'src/style/breakpoints';

/**
 * @type import('App').AppStyledComponent<{ small?: boolean }>
 */
export const StyledBtn = styled.button(
  {
    position: 'relative',
    cursor: 'pointer',
    borderRadius: 5,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px',
    transitionProperty: 'all',
    transitionDuration: '0.3s',
    outlineStyle: 'none',
    transitionTimingFunction: 'ease-in-out',
    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      transform: 'scaleX(0)',
      transformOrigin: '0 50%',
      transitionProperty: 'transform',
      transitionDuration: '0.3s',
      transitionTimingFunction: 'ease-in-out',
    },
    '& > span': {
      display: 'block',
      padding: '17px 40px',
      position: 'relative',
    },
    '&:hover:before': {
      transform: 'scaleX(1)',
    },
    '&:hover': {
      transform: 'translateY(1px)',
    },
  },
  ({ isSmall = false }) => ({
    fontSize: isSmall ? 17 : 19,
    lineHeight: isSmall ? '20px' : '31px',
    [mediaQueries.phoneOnly]: {
      fontSize: isSmall ? 16 : 18,
      lineHeight: isSmall ? '20px' : '26px',
    },

    '& > span': {
      padding: isSmall ? '13px 24px' : '16px 35px',
    },
  }),
  ({ theme }) => ({
    color: (() => {
      switch (theme.themeName) {
        case 'light':
        case 'primary':
        case 'secondary':
          return theme.backgroundColor;
        case 'dark':
        case 'ternary':
          return theme.foregroundColor;
        default:
      }
    })(),
    backgroundColor: (() => {
      switch (theme.themeName) {
        case 'light':
        case 'secondary':
        case 'dark':
          return theme.colors.purple;
        case 'primary':
          return theme.foregroundColor;
        case 'ternary':
          return theme.colors.white;
        default:
      }
    })(),
    '&:before': {
      backgroundColor: (() => {
        switch (theme.themeName) {
          case 'light':
          case 'secondary':
          case 'dark':
            return 'rgba(255,255,255, 0.1)';
          case 'primary':
            return theme.colors.lightGrey;
          case 'ternary':
            return theme.colors.lightGrey;
          default:
        }
      })(),
    },
    '&:hover': {
      color: (() => {
        switch (theme.themeName) {
          case 'light':
          case 'secondary':
          case 'dark':
            return theme.colors.white;
          case 'primary':
            return theme.backgroundColor;
          case 'ternary':
            return theme.colors.black;
          default:
        }
      })(),
    },
    [mediaQueries.phoneOnly]: {
      width: '100%',
    },
    ...theme.fonts.HKCompakt.bold,
  })
);

export const StyledBtnSecondary = styled(StyledBtn)(({ theme }) => {
  return {
    // We only handle secondary for primary theme
    // '& > span': {
    //   padding: '15px 38px', // padding -2 to compensate border
    // },
    backgroundColor: theme.backgroundColor,
    color: theme.themeName === 'secondary' ? colors.purple : theme.foregroundColor,
    '&:before': {
      backgroundColor: 'rgba(255,255,255, 0.1)',
    },
    '&:hover': {
      color: theme.themeName === 'secondary' ? colors.purple : theme.foregroundColor,
    },
    border: `1px solid ${theme.themeName === 'secondary' ? colors.purple : theme.foregroundColor}`, // '1px solid ' + theme.foregroundColor
  };
});

export const StyledAnchor = styled(StyledBtn.withComponent(Link), {
  shouldForwardProp: (props) => props !== 'isSmall',
})({
  textDecoration: 'none',
  display: 'inline-block',
});

export const StyledAnchorSecondary = styled(StyledAnchor)(
  ({ theme }) => ({
    // We only handle secondary for primary theme

    backgroundColor: 'transparent',
    color: theme.themeName === 'secondary' ? colors.purple : theme.foregroundColor,
    '&:before': {
      backgroundColor: 'rgba(255,255,255, 0.1)',
    },
    '&:hover': {
      color: theme.themeName === 'secondary' ? colors.purple : theme.foregroundColor,
    },
    border: `1px solid ${theme.themeName === 'secondary' ? colors.purple : theme.foregroundColor}`, // '1px solid ' + theme.foregroundColor
  }),
  ({ isSmall }) => ({
    '& > span': {
      padding: isSmall ? '12px 22px' : '16px 33px', // padding -2 to compensate border
    },
  })
);
